export interface LupaProduct {
  image: string
  code: string
  color: string[]
  gender: string[]
  originalPrice: number
  isNew: boolean
  variants: LupaProductVariant[]
  taxonId: number[]
  price: number
  name: string
  id: number
  brand: string[]
  slug: string
  isInStock: boolean
}

export interface LupaBrandsFacet {
  item: any[]
  key: string
  label: string
  type: string
}

export interface LupaProductVariants {
  [key: string]: LupaProductVariant
}

export type LupaProductUnparsed = Omit<LupaProduct, 'variants'> & {
  variants: string
}

export interface LupaProductVariant {
  code: string
  descriptor: string
  enabled: boolean
  isInStock: boolean
  options: LupaProductVariantOption[]
  id?: string
  originalPrice?: number
  price?: number
  minimumPriceIn30Days?: number | null
}

export interface LupaProductVariantOption {
  option_code: string
  option_name: string
  option_value: string
  option_value_code: string
}

export type LupaProductVariantUnparsed = Omit<LupaProductVariant, 'option'> & {
  options?: {
    [key: string]: LupaProductVariantOption
  }
}

export type LupaFacet = LupaTermsFacet | LupaStatsFacet

export enum LupaFacetTypes {
  Terms = 'terms',
  Stats = 'stats',
}

export enum LupaFacetKeys {
  Brand = 'brand',
}

export enum FilterLayout {
  Dropdown,
  Collapsible,
}

export interface LupaTermsFacet {
  key: string
  label: string
  items: LupaFacetItem[]
  type: LupaFacetTypes.Terms
}

export interface LupaStatsFacet {
  key: string
  label: string
  max: number
  min: number
  type: LupaFacetTypes.Stats
}

export interface LupaFacetItem {
  title: string
  count: number
}

export type LupaResponseDto = Omit<LupaItemsDto, 'items'> & {
  items: LupaProductUnparsed[]
}

export interface LupaItemsDto {
  searchText: string
  total: number
  items: LupaProduct[]
  facets: LupaFacet[]
  filters: any
  limit: number
  offset: number
}

export type LupaResponse = Partial<LupaItemsDto> & { error?: string }

export const URL_STATS_DELIMITER = '++'
export type LupaFacetMapItemValue = string | string[] | number[] | StatsRange

export type LupaFacetMapItem = {
  [key: string]: {
    value: LupaFacetMapItemValue
    type: LupaFacetTypes
  }
}
export type LupaFacetMap = Map<
  string,
  { value: LupaFacetMapItemValue; type: LupaFacetTypes }
> | null

export type StatsRange = {
  gte: string
  lte: string
}

export enum SortDirection {
  PriceHighToLow = '0',
  PriceLowToHigh = '1',
  Discount = '2',
  NewArrivals = '3',
}

export enum LupaSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const LUPA_TAXON_FILTER_KEY = 'taxonId'
