'use client'

import lazy from 'next/dynamic'
import { ReactNode, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import ProductAddedNotification from '@components/cart/ProductAddedNotification'
import GlobalLoader from '@components/common/GlobalLoader/GlobalLoader'
import { useLayout } from '@lib/hooks/useLayout'
import { useOnComplete } from '@app/router-events'

const InitContextsWrapper = lazy(
  () => import('@lib/contexts/ContextsProvider/InitContextsWrapper'),
  {
    ssr: false,
  }
)

const CartProvider = lazy(() => import('@lib/contexts/CartContext/context'), {
  ssr: false,
})

const ManagedUIContext = lazy(() => import('@lib/contexts/UIContext/context'), {
  ssr: false,
})

const ProductContentProvider = lazy(
  () => import('@lib/contexts/ProductContentContext/context'),
  {
    ssr: false,
  }
)

const ContextsProvider = ({
  children,
}: {
  children: ReactNode
  lang: string
}) => {
  useOnComplete()
  const { isDesktop } = useLayout()
  const [gtmIsSent, setGtmIsSent] = useState(false)

  useEffect(() => {
    if (!gtmIsSent && process.env.GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize([
        {
          trackingId: process.env.GOOGLE_ANALYTICS_ID,
        },
      ])

      setGtmIsSent(true)
    }
  }, [gtmIsSent])

  return (
    <ManagedUIContext>
      <CartProvider>
        <ProductContentProvider>
          <InitContextsWrapper>
            <>
              {!isDesktop && <ProductAddedNotification />}
              <GlobalLoader />
              {children}
            </>
          </InitContextsWrapper>
        </ProductContentProvider>
      </CartProvider>
    </ManagedUIContext>
  )
}

export default ContextsProvider
