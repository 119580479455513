import { LupaFacetMap, LupaFacetMapItem, LupaFacetTypes } from '@lib/types/lupa'

export default (filter: LupaFacetMapItem, filterMap: LupaFacetMap) => {
  const filterKey = Object.keys(filter)?.[0]
  const updatedFilters: LupaFacetMap = new Map(filterMap)

  if (filter[filterKey]?.type === LupaFacetTypes.Terms) {
    const filterValue = filter[filterKey].value as unknown as string
    const currentFilterValues = updatedFilters?.get(filterKey)?.value as
      | string[]
      | undefined

    if (currentFilterValues) {
      // Remove if the same filter is found.
      if (currentFilterValues?.includes(filterValue)) {
        const newFilters = currentFilterValues.filter(
          (value) => value !== filterValue
        )

        if (newFilters.length) {
          updatedFilters?.set(filterKey, {
            value: newFilters,
            type: filter[filterKey].type,
          })
        } else {
          updatedFilters?.delete(filterKey)
        }
        // Add if currently selected filter is not found.
      } else {
        updatedFilters?.set(filterKey, {
          value: currentFilterValues.concat(filterValue),
          type: filter[filterKey].type,
        })
      }
      // Add if it's a new filter.
    } else {
      updatedFilters?.set(filterKey, {
        type: filter[filterKey].type,
        value: [filterValue],
      })
    }
  }

  if (filter[filterKey]?.type === LupaFacetTypes.Stats) {
    const filterValue = filter[filterKey].value as number[]

    // Passed as empty array if values aren't changed
    if (!filterValue.length) {
      updatedFilters?.delete(filterKey)
    } else {
      updatedFilters?.set(filterKey, {
        type: filter[filterKey].type,
        value: filterValue,
      })
    }
  }

  return updatedFilters
}
