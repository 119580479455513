'use client'

import isEqual from 'lodash.isequal'
import { createContext, FC, ReactNode, useContext, useReducer } from 'react'
import { ProductContentActions } from '@lib/constants/context'
import { DEFAULT_PAGE_LIMIT } from '@lib/constants/lupa'
import { Taxon } from '@lib/types'
import {
  LupaFacet,
  LupaFacetMap,
  LupaFacetMapItem,
  LupaItemsDto,
  LupaProduct,
} from '@lib/types/lupa'
import parseLupaResponseFilters from '@lib/utils/filters/parseLupaResponseFilters'
import updateFilterMap from '@lib/utils/filters/updateFilterMap'

interface ProductContentContextState {
  lupaItems?: LupaItemsDto | null
  products: LupaProduct[]
  limit: number
  offset: number
  total: number
  category?: Taxon | null
  isLoading: boolean
  selectedFilters: LupaFacetMap | null
  setSelectedFilters: (filters: LupaFacetMapItem, facets: LupaFacet[]) => void
  temporaryFilters: LupaFacetMap | null
  setTemporaryFilters: (facets: LupaFacetMapItem) => void
  resetTemporaryFilters: () => void
  setLupaItems: (
    lupaDto: LupaItemsDto,
    initiator: 'SEARCH' | 'CATEGORY',
    appendList: boolean
  ) => void
  setCategory: (value: Taxon) => void
  setLoadingState: (value: boolean) => void
  resetProductContent: () => void
  initiator: 'SEARCH' | 'CATEGORY' | null
}

const initialState = {
  lupaItems: null,
  category: null,
  limit: DEFAULT_PAGE_LIMIT,
  offset: 0,
  total: 0,
  products: [],
  isLoading: false,
  selectedFilters: null,
  setSelectedFilters: () => {},
  temporaryFilters: null,
  setTemporaryFilters: () => {},
  resetTemporaryFilters: () => {},
  setLupaItems: () => {},
  setCategory: () => {},
  setLoadingState: () => {},
  resetProductContent: () => {},
  initiator: null,
}

const ProductContentContext =
  createContext<ProductContentContextState>(initialState)

ProductContentContext.displayName = 'ProductContent'

type Action =
  | {
      type: ProductContentActions.setLupaItems
      value: {
        lupaDto: LupaItemsDto
        initiator: 'SEARCH' | 'CATEGORY'
        appendList: boolean
      }
    }
  | {
      type: ProductContentActions.setCategory
      value: Taxon
    }
  | {
      type: ProductContentActions.setLoadingState
      value: boolean
    }
  | {
      type: ProductContentActions.setLupaFacets
      value: {
        filters: LupaFacetMapItem
        facets: LupaFacet[]
      }
    }
  | {
      type: ProductContentActions.setTemporaryLupaFacets
      value: LupaFacetMapItem
    }
  | {
      type: ProductContentActions.resetTemporaryLupaFacets
    }
  | {
      type: ProductContentActions.resetProductContent
    }

const productContentReducer = (
  state: ProductContentContextState,
  action: Action
) => {
  switch (action.type) {
    case ProductContentActions.setLupaItems: {
      const { lupaDto, initiator, appendList } = action.value
      const { limit, offset, total, facets, filters, items } = lupaDto

      const parsedFilters = parseLupaResponseFilters({
        facets,
        filters,
      })

      const products = appendList
        ? [...(state.products ?? []), ...items]
        : items

      return {
        ...state,
        ...parsedFilters,
        products,
        limit,
        offset,
        total,
        lupaItems: lupaDto,
        initiator,
      }
    }
    case ProductContentActions.setLoadingState: {
      return {
        ...state,
        isLoading: action.value,
      }
    }
    case ProductContentActions.setCategory: {
      return {
        ...state,
        category: action.value,
      }
    }
    case ProductContentActions.setTemporaryLupaFacets: {
      return {
        ...state,
        temporaryFilters: updateFilterMap(action.value, state.temporaryFilters),
      }
    }
    case ProductContentActions.setLupaFacets: {
      const filters = parseLupaResponseFilters({
        facets: action.value.facets,
        filters: action.value.filters,
      })

      return {
        ...state,
        ...filters,
      }
    }
    case ProductContentActions.resetTemporaryLupaFacets: {
      if (!isEqual(state.selectedFilters, state.temporaryFilters)) {
        return {
          ...state,
          temporaryFilters: state.selectedFilters,
        }
      }

      return state
    }
    case ProductContentActions.resetProductContent: {
      return initialState
    }
  }
}

const ProductContentProvider: FC<{ children?: ReactNode }> = (props) => {
  const [state, dispatch] = useReducer(productContentReducer, initialState)

  const setLupaItems = (
    lupaDto: LupaItemsDto,
    initiator: 'SEARCH' | 'CATEGORY',
    appendList: boolean
  ) => {
    dispatch({
      type: ProductContentActions.setLupaItems,
      value: { lupaDto, initiator, appendList },
    })
  }

  const setLoadingState = (isLoading: boolean) => {
    dispatch({
      type: ProductContentActions.setLoadingState,
      value: isLoading,
    })
  }

  const setCategory = (category: Taxon) => {
    dispatch({
      type: ProductContentActions.setCategory,
      value: category,
    })
  }

  const setSelectedFilters = (
    filters: LupaFacetMapItem,
    facets: LupaFacet[]
  ) => {
    dispatch({
      type: ProductContentActions.setLupaFacets,
      value: {
        filters,
        facets,
      },
    })
  }

  const setTemporaryFilters = (facets: LupaFacetMapItem) => {
    dispatch({
      type: ProductContentActions.setTemporaryLupaFacets,
      value: facets,
    })
  }

  const resetTemporaryFilters = () => {
    dispatch({
      type: ProductContentActions.resetTemporaryLupaFacets,
    })
  }

  const resetProductContent = () => {
    dispatch({
      type: ProductContentActions.resetProductContent,
    })
  }

  const value = {
    ...state,
    setLupaItems,
    setCategory,
    setLoadingState,
    setSelectedFilters,
    setTemporaryFilters,
    resetTemporaryFilters,
    resetProductContent,
  }

  return <ProductContentContext.Provider value={value} {...props} />
}

export const useProductContent = () => {
  const context = useContext(ProductContentContext)
  if (context === undefined) {
    throw new Error(
      `useProductContent must be used within a ProductContentProvider`
    )
  }
  return context
}

export default ProductContentProvider
