import LoadingOverlay from '@components/ui/Loading/LoadingOverlay'
import { useCart, useProductContent } from '@lib/contexts'
import { useChannel } from '@lib/hooks/common/useChannel'
import { useCustomer } from '@lib/hooks/data/customer/useCustomer'

const GlobalLoader = () => {
  const { isLoading: isProductContentLoading } = useProductContent()
  const { isLoading: isCartLoading } = useCart()
  const { isLoading: isChannelLoading } = useChannel()
  const { isLoading: isCustomerLoading } = useCustomer()

  if (
    isCartLoading ||
    isProductContentLoading ||
    isChannelLoading ||
    isCustomerLoading
  ) {
    return <LoadingOverlay />
  }

  return null
}

export default GlobalLoader
