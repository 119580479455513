'use client'

import cn from 'clsx'
import { FC, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { CloseIcon, AlertCircleIcon, CheckCircleIcon } from '@components/icons'
import { useCart } from '@lib/contexts'
import { useLayout } from '@lib/hooks/useLayout'
import { useClientTranslation } from '@app/i18n/client'

const ProductAddedNotification: FC = () => {
  const { isDesktop } = useLayout()
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)
  const { productAddToCartState } = useCart()

  const { t } = useClientTranslation('notifications')

  useEffect(() => {
    setIsNotificationVisible(!!productAddToCartState)
    if (productAddToCartState === 'success') {
      toast.success(t('Product added to cart successfully'), {
        icon: <CheckCircleIcon width={28} height={28} />,
        toastId: 'product-notification-success-toast',
      })
      return
    }

    if (productAddToCartState === 'error') {
      toast.error(t('Unable to add product to cart'), {
        icon: <AlertCircleIcon width={28} height={28} />,
        toastId: 'product-notification-error-toast',
      })
      return
    }
    toast.dismiss()
  }, [productAddToCartState, t])

  if (!isDesktop) {
    return (
      <ToastContainer
        position="top-center"
        hideProgressBar
        closeButton={
          <div className="flex items-center p-2">
            <CloseIcon />
          </div>
        }
      />
    )
  }

  return (
    <div
      className={cn(
        'mb-2 flex items-center justify-between gap-[10px] rounded border p-2',
        productAddToCartState === 'success'
          ? 'border-success bg-[#ECFFF4]'
          : 'border-error bg-[#FFE9E9]',
        !isNotificationVisible && 'hidden'
      )}
    >
      <div
        className={cn(
          productAddToCartState === 'success' ? 'text-success' : 'text-error'
        )}
      >
        {productAddToCartState === 'success' ? (
          <CheckCircleIcon width={28} height={28} />
        ) : (
          <AlertCircleIcon width={28} height={28} />
        )}
      </div>
      <span className="font-medium">
        {productAddToCartState === 'success'
          ? t('Product added to cart successfully')
          : t('Unable to add product to cart')}
      </span>
      <button
        onClick={() => setIsNotificationVisible(false)}
        type="button"
        className="p-2 transition-opacity hover:opacity-70"
      >
        <CloseIcon />
      </button>
    </div>
  )
}

export default ProductAddedNotification
